<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header-v2
        title="Holders"
        icon="mdi-account-supervisor"
        :padding="0"
      />
      <common-page-loader
        v-if="loading"
      />
      <v-row
        :justify="'end'"
        class="mt-8"
      >
        <common-trs-btn
          v-if="holdersData.length>0"
          type="primary"
          large
          class="white--text mr-3"
          text
          :to="newScenarioRoute"
        >
          <v-icon class="mr-2">
            mdi-plus-circle
          </v-icon>
          Invite Holder
        </common-trs-btn>
      </v-row>
      <holders-list
        v-model="holdersData"
      />

      <common-trs-data-table
        :headers="headers"
        :items="holdersData"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalCount"
      >
        <template v-slot:[`item.username`]="{ item }">
          {{ item.username }}
        </template>
        <template v-slot:[`item.trellis_uid`]="{ item }">
          {{ item.trellis_uid }}
        </template>
        <template v-slot:[`item.first_name`]="{ item }">
          {{ item.first_name }}
        </template>
        <template v-slot:[`item.last_name`]="{ item }">
          {{ item.last_name }}
        </template>
        <template v-slot:[`item.invite_status`]="{ item }">
          {{ $titlecase(item.invite_status) }}
        </template>
        <template v-slot:[`item.last_logged_on`]="{ item }">
          {{ $date(item.last_logged_on) }}
        </template>
        <template v-slot:[`item.date_of_invitation`]="{ item }">
          {{ $date(item.date_of_invitation) }}
        </template>
        <template v-slot:[`item.date_onboarded`]="{ item }">
          {{ $date(item.date_onboarded) }}
        </template>
        <template v-slot:no-data="{ }">
          <no-data-msg
            v-if="holdersData.length===0 && !loading"
            :route-detail="newScenarioRoute"
          >
            <template v-slot:msg>
              No holders found.
            </template>
            <template v-slot:action-btn-label>
              Invite Holder
            </template>
            <template v-slot:tooltip-msg>
              Invite Holder
            </template>
          </no-data-msg>
        </template>
      </common-trs-data-table>
    </template>
  </common-trs-page-container>
</template>
<script>
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import * as captableAdminService from '@/services/captable-admin-service'

  const TABLE_HEADERS = [
    { text: 'Username', value: 'username', sortable: false },
    { text: 'Trellis UID', value: 'trellis_uid', sortable: false },
    { text: 'First Name', value: 'first_name', sortable: false },
    { text: 'Last Name', value: 'last_name', sortable: false },
    { text: 'Status', value: 'invite_status', sortable: false },
    { text: 'Last Logged in', value: 'last_logged_on', sortable: false },
    { text: 'Invited', value: 'date_of_invitation', sortable: false },
    { text: 'Onboarded', value: 'date_onboarded', sortable: false },
    // { text: '', value: 'actions', sortable: false, align: 'right' },
  ]
  export default {
    name: 'HolderIndex',

    components: {
      NoDataMsg,
    },

    data: () => ({
      holdersData: [],
      newScenarioRoute: { name: 'AddHolder' },
      loading: false,
      checkedList: [],
      headers: TABLE_HEADERS,
      options: {
        sortBy: ['created_on'],
        sortDesc: [false],
      },
      totalCount: 0,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      options: {
        handler () {
          this.loadHolders()
        },
        deep: true,
      },
    },

    mounted () {
      this.loadHolders()
    },

    methods: {
      async loadHolders () {
        try {
          this.loading = true
          const resp = await captableAdminService.getHoldersList(this.captableId, {
            ...this.options,
          })
          this.totalCount = resp.count
          this.holdersData = resp.results
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
    },
  }
</script>
