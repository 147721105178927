var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-trs-page-container',{scopedSlots:_vm._u([{key:"page-content",fn:function(){return [_c('common-app-page-header-v2',{attrs:{"title":"Holders","icon":"mdi-account-supervisor","padding":0}}),(_vm.loading)?_c('common-page-loader'):_vm._e(),_c('v-row',{staticClass:"mt-8",attrs:{"justify":'end'}},[(_vm.holdersData.length>0)?_c('common-trs-btn',{staticClass:"white--text mr-3",attrs:{"type":"primary","large":"","text":"","to":_vm.newScenarioRoute}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Invite Holder ")],1):_vm._e()],1),_c('holders-list',{model:{value:(_vm.holdersData),callback:function ($$v) {_vm.holdersData=$$v},expression:"holdersData"}}),_c('common-trs-data-table',{attrs:{"headers":_vm.headers,"items":_vm.holdersData,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.trellis_uid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trellis_uid)+" ")]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" ")]}},{key:"item.invite_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$titlecase(item.invite_status))+" ")]}},{key:"item.last_logged_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.last_logged_on))+" ")]}},{key:"item.date_of_invitation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.date_of_invitation))+" ")]}},{key:"item.date_onboarded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.date_onboarded))+" ")]}},{key:"no-data",fn:function(ref){return [(_vm.holdersData.length===0 && !_vm.loading)?_c('no-data-msg',{attrs:{"route-detail":_vm.newScenarioRoute},scopedSlots:_vm._u([{key:"msg",fn:function(){return [_vm._v(" No holders found. ")]},proxy:true},{key:"action-btn-label",fn:function(){return [_vm._v(" Invite Holder ")]},proxy:true},{key:"tooltip-msg",fn:function(){return [_vm._v(" Invite Holder ")]},proxy:true}],null,true)}):_vm._e()]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }